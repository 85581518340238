<script lang="ts" setup>
const config = useRuntimeConfig();
interface Props {
  src: string;
  size?: "MEDIUM" | "LARGE";
  alignment?: "CENTER" | "BOTTOM" | "TOP";
}

const props = withDefaults(defineProps<Props>(), {
  size: "MEDIUM",
  alignment: "BOTTOM",
});

const fullUrl = computed(() => {
  const width = 1920;
  const src = props.src.replace(/\/plain\//, `/w:${width}/plain/`);
  return `${config.public.imageBaseUrl}/${src}.light.png`;
});
</script>

<template>
  <div
    class="container bg-cover bg-no-repeat"
    :class="[
      {
        'h-[35vh]': size === 'MEDIUM',
        'h-[45vh]': size === 'LARGE',
        'bg-[center_center]': alignment === 'CENTER',
        'bg-[center_bottom]': alignment === 'BOTTOM',
        'bg-[center_top]': alignment === 'TOP',
      },
    ]"
    :style="{
      backgroundImage: `url(${fullUrl})`,
    }"
  />
</template>
